// ** React Imports
import { ReactNode, useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Hook Imports
import { useAuth } from 'src/hooks/useAuth'
import { useSettings } from 'src/@core/hooks/useSettings'

/**
 *  Set Home URL based on User Roles
 */
export const getHomeRoute = (isAuth: boolean) => {
  return isAuth ? '/home' : '/login'
}

const Home = () => {
  // ** Hooks
  const auth = useAuth()
  const router = useRouter()
  const { settings } = useSettings()

  useEffect(() => {
    if (!router.isReady || auth.loading) return

    const homeRoute = getHomeRoute(!!auth.user)

    router.replace(homeRoute)
  }, [router, auth])

  return <Spinner settings={settings} />
}

Home.getLayout = (page: ReactNode) => page

export default Home
